import MySpaceProfile from "@/components/MySpace/Profile/MySpaceProfile";
import MySpaceRequeriments from "@/components/MySpace/MySpaceRequeriments";
import MySpaceSchedule from "@/components/MySpace/MySpaceSchedule";
import MySpaceTasks from "@/components/MySpace/MySpaceTasks";
import Layout from "@/components/layout";
import { UserData } from "@/lib/dto/user";
import { DashboardPage } from "@/lib/features/auth/DashboardPage";
import {
  useGetProfileQuery,
  useGetSupplierUsersPaginatedQuery,
} from "@/lib/services/user";
import { UserIcon } from "@heroicons/react/outline";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import Select, { components } from "react-select";

const { SingleValue } = components;
const IconOption = (props: any) => (
  <SingleValue {...props} className="flex items-center text-sm bg-slate-200">
    <UserIcon className="h-4 w-4 mr-2" />
    {props.data.user.first_name} {props.data.user.last_name}
  </SingleValue>
);

const MySpace: DashboardPage = () => {
  const { data } = useGetSupplierUsersPaginatedQuery({
    page_size: 100,
    filters: {
      only_active: true,
    },
  });
  const supplierUsers = data?.results;
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const profileResult = useGetProfileQuery();
  const user = profileResult?.data?.user;

  useEffect(() => {
    if (user && user.id && supplierUsers && !selectedUser) {
      setSelectedUser(supplierUsers?.find((u) => u.user.id === user.id));
    }
  }, [user, supplierUsers]);

  return (
    <Layout title={t`My Space`} current="Inventory" hideTitle>
      <div className="flex-1 flex flex-col h-screen overflow-y-auto">
        <div className="p-4 flex gap-4 items-center mt-4 justify-between">
          <h1 className="text-xl font-semibold" id="section-title">
            {t`My Space`}
          </h1>
          {user?.is_superuser && (
            <Select
              className="w-60"
              options={supplierUsers}
              value={selectedUser}
              placeholder={t`Select`}
              getOptionLabel={(option) =>
                option?.user.first_name + " " + option?.user.last_name
              }
              getOptionValue={(option) => option.id!.toString()}
              onChange={(option) => setSelectedUser(option as UserData)}
              components={{ SingleValue: IconOption }}
            />
          )}
        </div>
        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-4 border border-gray-200 ml-4 rounded-lg">
            <MySpaceProfile user={selectedUser} />
          </div>
          <div className="col-span-6 mr-4">
            <MySpaceRequeriments supplier_user_id={selectedUser?.id} />
            <div className="mt-6"></div>
            <MySpaceSchedule supplier_user_id={selectedUser?.id} />
            <div className="mt-6"></div>
            <MySpaceTasks supplier_user_id={selectedUser?.id} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
MySpace.auth = true;
export default MySpace;
